import Vue from 'vue';
import Swal from 'sweetalert2';
import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import { areasManager } from '@/store/services/areas_manager';
import { managers } from '@/store/services/managers';
//import { isDraft } from '@/store/services/register-status';
//import { responseApi } from '../../format';
import FormHeader from '@/components/inspections/formats/hseq-sso-f-17/headers/FormHeader.vue';
import FormBody from '@/components/inspections/formats/hseq-sso-f-17/bodies/FormBody.vue';
import ToolDialog from '@/components/inspections/formats/hseq-sso-f-17/bodies/ToolDialog/ToolDialog.vue';
import ResponsibleInpection from '@/components/global/ResponsibleInpection.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import RegistryCreator from '@/components/global/RegistryCreator.vue';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'RegistersShowHseqSsoF17',
	data: () => ({
		title: '',
		// NEW
		breadcrumbs: [],
		steps: {
			current: 1,
			views: [1],
		},
		headers: [
			{
				text: 'Nombre',
				value: 'name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Inspección',
				value: 'inspeccion',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Acciones',
				value: 'actions',
				sortable: false,
				align: 'end',
				class: 'primary--text',
			},
		],
		clients: [],
		managers: managers,
		services: [],
		areaManager: areasManager[0],
		dialogObservation: false,
		bodyId: null,
		date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
			.toISOString()
			.substr(0, 10),
		toolsInspection: [],
		isLoadingSave: false,
		isLoadingData: false,
		toolDialog: false,
		validationErrors: [],
	}),
	computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.width < 960;
		},
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		...mapState('security', ['managements', 'costCenters']),
		totalQuestions: function () {
			let total = 0;
			this.inspection.bodies.forEach((body) => {
				total += body.items.length;
			});
			return total;
		},
		totalItemsApply: function () {
			let total = 0;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (['SI', 'NO'].includes(item.value)) {
							total++;
						}
					});
				});
			}
			return total;
		},
		totalItemsResponse: function () {
			let total = 0;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (item.value && item.value != '') {
							total++;
						}
					});
				});
			}
			return total;
		},
		optionsAnswer: function () {
			let options = [];
			if (this.inspection) {
				this.inspection.bodies.forEach((body) => {
					body.options_answer.forEach((option) => {
						let exists = false;
						options.forEach((optionItem) => {
							if (optionItem.value == option.value) {
								exists = true;
							}
						});
						if (!exists) {
							options.push({
								label: option.label,
								value: option.value,
								color: option.color,
								total: 0,
							});
						}
					});
				});

				// SET TOTALS
				this.inspection.bodies.forEach((body) => {
					body.items.forEach((item) => {
						options.forEach((optionItem, indexOI) => {
							if (item.value == optionItem.value) {
								options[indexOI].total++;
							}
						});
					});
				});
			}
			return options;
		},
		totalAnswers: function () {
			let total = 0;
			this.optionsAnswer.forEach((option) => {
				total += option.total;
			});
			return total;
		},
		validateObservations: function () {
			let result = true;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (
							item.value &&
							item.value == 'NO' &&
							(!item.observations || item.observations == '')
						) {
							result = false;
						}
					});
				});
			}
			return result;
		},
		// validateQuestions() {
		//   let result = true;
		//   this.inspection.bodyQuestions.forEach((group) => {
		//     group.items.forEach(item => {
		//       if ((!item.value || item.value === undefined || item.value == '') && item.required) {
		//         result = false;
		//       }
		//     });
		//   });
		//   return result;
		// },
		completedQuestionary: function () {
			return (
				this.totalItemsResponse == this.totalQuestions &&
				this.totalItemsResponse > 0 &&
				this.validateHeader() &&
				this.validateObservations &&
				this.validateQuestions() &&
				this.validateTools()
			);
		},
		completedHeaders: function () {
			return this.validateHeader();
		},
	},
	created() {
		this.initialize();
	},
	watch: {
		'steps.current': function (newVal) {
			if (!this.steps.views.includes(newVal)) {
				this.steps.views.push(newVal);
			}
		},
	},
	methods: {
		...mapActions('inspection', ['find']),
		...mapActions('register', ['create', 'draft', 'findById', 'updateStatus']),
		...mapActions('security', ['getManagements']),
		...mapActions('tool', ['listTools']),

		textTransform,

		initialize() {
			$EventBus.$emit('showSubNav', false);
			this.isLoadingData = true;
			this.find({
				success: () => {
					this.getRegister();
				},
				fail: () => {
					this.isLoadingData = false;
				},
				id: this.$route.params?.inspectionId,
			});
			this.listTools();
		},
		getRegister() {
			this.findById({
				success: () => {
					this.setData();
					this.setBreadCrumbs();
					this.isLoadingData = false;
				},
				fail: () => {
					this.isLoadingData = false;
				},
				params: {
					inspectionId: this.$route.params?.inspectionId,
					registerId: this.$route.params?.id,
				},
			});
		},
		setData() {
			const headers = this.register?.registerHeaders || null;
			const tools = this.register?.registerTool || null;

			if (this.register?.status == 'DRAFT') {
				this.headers.push({
					text: 'Acciones',
					value: 'actions',
					sortable: false,
					align: 'end',
					class: 'primary--text',
				});
			}

			if (Array.isArray(headers)) {
				headers.forEach((header) => {
					this.$store.dispatch('inspection/updateInputHeader', {
						id: header.header_id,
						value:
							header.value && header.value != '' && !isNaN(header.value)
								? parseInt(header.value)
								: header.value,
						disabled: this.isDisabled(),
					});
				});
			}

			this.register.bodyRegisters.forEach((bodyRegister) => {
				this.inspection.bodies.forEach((bodyGroup, bodyGroupI) => {
					bodyGroup.items.forEach((bodyItem, bodyItemI) => {
						if (bodyItem.id == bodyRegister.body_id) {
							Vue.set(
								this.inspection.bodies[bodyGroupI].items[bodyItemI],
								'value',
								bodyRegister.compliance
							);
							Vue.set(
								this.inspection.bodies[bodyGroupI].items[bodyItemI],
								'observations',
								bodyRegister.observations
							);
						}
					});
				});
			});

			if (Array.isArray(tools)) {
				let newTools = tools.map((e) => {
					return {
						id: e.tool_id,
						name: e.tool.name,
						value: e.status,
						observations: e.observations,
					};
				});
				this.toolsInspection = [...newTools];
			}

			this.register.bodyQuestionRegisters.forEach((bodyRegister) => {
				this.inspection.bodyQuestions.forEach((bodyGroup, bodyGroupI) => {
					bodyGroup.items.forEach((bodyItem, bodyItemI) => {
						if (bodyItem.id == bodyRegister.body_question_id) {
							Vue.set(
								this.inspection.bodyQuestions[bodyGroupI].items[
									bodyItemI
								],
								'value',
								bodyRegister.value
							);
						}
					});
				});
			});
		},
		// handleSuccess() {
		//   this.setBreadCrumbs();
		//   if (this.$route.params.registerId) {
		//     this.setRegisterData();
		//   }
		// },
		getDataManagements() {
			this.services = [];
			this.getManagements({
				success: () => {
					this.managements.map((management) => {
						this.services = [...this.services, ...management.costCenters];
					});
				},
				fail: () => {},
			});
		},
		// getData(id) {
		//   this.find({
		//     success: this.handleSuccess,
		//     fail: this.handleFail,
		//     id: id,
		//   });
		// },
		// getDataRegister() {
		//   this.findById({
		//     success: () => {
		//       if (isDraft(this.register.status)) {
		//         this.getData(this.register.inspection_id);
		//       } else {
		//         this.$router.push(
		//           '/dashboard/inspections/' + this.register.inspection_id + '/registers'
		//         );
		//         $EventBus.$emit('showSnack', 'warning', 'Registro no encontrado.');
		//       }
		//     },
		//     fail: () => { },
		//     id: this.$route.params.registerId,
		//   });
		// },
		setRegisterData() {
			this.inspection.register_id = this.register.id;
			// SET BODIES
			this.register.bodyRegisters.forEach((bodyRegister) => {
				this.inspection.bodies.forEach((bodyGroup, bodyGroupI) => {
					bodyGroup.items.forEach((bodyItem, bodyItemI) => {
						if (bodyItem.id == bodyRegister.body_id) {
							Vue.set(
								this.inspection.bodies[bodyGroupI].items[bodyItemI],
								'value',
								bodyRegister.compliance
							);
							Vue.set(
								this.inspection.bodies[bodyGroupI].items[bodyItemI],
								'observations',
								bodyRegister.observations
							);
						}
					});
				});
			});

			// SET HEADERS
			this.register.registerHeaders.forEach((registerHeader) => {
				this.inspection.headers.forEach((header, headerI) => {
					if (header.id == registerHeader.header_id) {
						Vue.set(
							this.inspection.headers[headerI],
							'value',
							registerHeader.value
						);
						// CLIENT
						if (header.key == 'client') {
							this.clients.forEach((client) => {
								if (client.id == this.register.client_id) {
									Vue.set(
										this.inspection.headers[headerI],
										'value',
										this.register.client_id
									);
								}
							});
						}
						// MANAGER
						if (header.key == 'manager') {
							this.clients.forEach((client) => {
								if (client.id == this.register.manager_id) {
									Vue.set(
										this.inspection.headers[headerI],
										'value',
										this.register.manager_id
									);
								}
							});
						}
					}
				});
			});
		},
		validateHeader() {
			let result = true;
			if (this.inspection?.headers && Array.isArray(this.inspection?.headers)) {
				this.inspection.headers.forEach((header) => {
					if (
						!header.value ||
						header.value === undefined ||
						header.value == ''
					) {
						result = false;
					}
				});
			}
			return result;
		},
		validateGroup(index) {
			let result = true;
			this.inspection.bodies[index].items.forEach((body) => {
				if (
					!body.value ||
					body.value === undefined ||
					body.value == '' ||
					(body.value &&
						body.value === 'NO' &&
						(!body.observations || body.observations == ''))
				)
					result = false;
			});
			return result;
		},
		validateQuestions() {
			let result = true;
			this.inspection.bodyQuestions.forEach((group) => {
				group.items.forEach((item) => {
					if (
						(!item.value || item.value === undefined || item.value == '') &&
						item.required
					) {
						result = false;
					}
				});
			});
			return result;
		},
		validateTools() {
			let validated = false;
			if (this.inspection) {
				if (this.toolsInspection.length > 0 && !validated) {
					this.toolsInspection.forEach((tool) => {
						if (tool.value) {
							if (tool.value == 'M' || tool.value == 'R') {
								if (tool.observations && tool.observations !== '') {
									validated = true;
								} else {
									validated = false;
								}
							} else {
								validated = true;
							}
						} else {
							validated = false;
						}
					});
				} else {
					validated = false;
				}
			}
			return validated;
		},
		getValueHeader(field) {
			let value = '';
			this.inspection.headers.forEach((header) => {
				if (header.key == field) {
					value = header.value;
				}
			});
			return value;
		},
		getClientName() {
			let names = null;
			this.clients.forEach((client) => {
				if (client.id == this.getValueHeader('client')) {
					names = client.name + ' ' + client.last_name;
				}
			});
			return names;
		},
		getManagerName() {
			let names = null;
			this.managers.forEach((manager) => {
				if (manager.id == this.getValueHeader('manager')) {
					names = manager.name + ' ' + manager.last_name;
				}
			});
			return names;
		},
		getDateRegister() {
			let date = null;
			this.inspection.headers.forEach((header) => {
				if (header.key == 'date') {
					date = header.value;
				}
			});
			return date;
		},
		existHeader(keyHeader) {
			let result = false;
			this.inspection.headers.forEach((header) => {
				if (header.key == keyHeader) {
					result = true;
				}
			});
			return result;
		},
		getManagementId(costCenterId) {
			let id = null;
			this.managements.forEach((management) => {
				management.costCenters.forEach((costCenter) => {
					if (costCenter.id == costCenterId) {
						id = management.id;
					}
				});
			});
			return id;
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && found[propFound]) {
					found = found[propFound];
				}
			}
			return found || null;
		},
		groupData(status) {
			// INITIAL DATA
			const service_id = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'service',
				propFound: 'value',
			});

			let dataSend = {
				register: {
					register_id: this.register.id,
					inspection_id: this.inspection.id,
					format: this.inspection.format,
					service_id,
					status,
					date: this.findValueInArray({
						items: this.inspection?.headers,
						prop: 'key',
						val: 'date',
						propFound: 'value',
					}),
					manager_id: this.findValueInArray({
						items: this.inspection?.headers,
						prop: 'key',
						val: 'manager',
						propFound: 'value',
					}),
					management_id: this.findValueInArray({
						items: this.costCenters,
						prop: 'id',
						val: service_id,
						propFound: 'management_id',
					}),
					company_id: parseInt(localStorage.getItem('company_id')),
				},
				headers: [],
				bodies: [],
				bodyQuestions: [],
			};

			// SET HEADERS
			dataSend.headers = this.inspection.headers.map(function (header) {
				return { header_id: header.id, value: (header?.value || '').toString() };
			});

			// SET BODIES
			this.inspection.bodies.forEach((body) => {
				body.items.forEach((item) => {
					dataSend.bodies.push({
						compliance: item.value,
						observations: item.value == 'NO' ? item.observations : null,
						body_id: item.id,
					});
				});
			});

			// SET TOOLS
			dataSend.tools = this.toolsInspection.map((tool) => {
				if (tool?.value == 'B')
					return { tool_id: tool.id, status: (tool?.value || '').toString() };
				else
					return {
						tool_id: tool.id,
						status: tool?.value || '',
						observations: tool?.observations,
					};
			});

			// SET QUESTIONS
			this.inspection.bodyQuestions.forEach((group) => {
				group.items.forEach((item) => {
					dataSend.bodyQuestions.push({
						value: item.value,
						body_question_id: item.id,
					});
				});
			});

			return dataSend;
		},
		send(status = 'PENDING') {
			this.isLoadingSave = true;
			this.create({
				success: () => {
					this.isLoadingSave = false;
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
					this.backPage();
				},
				fail: () => {
					$EventBus.$emit(
						'showSnack',
						'error',
						'Algo salio mal, no se guardo la información.'
					);
					this.isLoadingSave = false;
				},
				data: this.groupData(status),
			});
		},

		addObservation(bodyId) {
			this.bodyId = bodyId;
			this.dialogObservation = true;
		},
		closeObservation(status) {
			this.dialogObservation = false;
			if (status) {
				$EventBus.$emit('showSnack', 'success', 'Observación añadida.');
			}
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		setBreadCrumbs() {
			this.breadcrumbs = [
				{
					text: 'Inspecciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsList',
					},
				},
				{
					text: this.inspection ? textTransform(this.inspection.name) : '',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsRegisterList',
						params: { id: this.inspection.format },
					},
				},
				{
					text: 'Nuevo registro',
					disabled: true,
					href: '/',
				},
			];
		},
		getLabel(value) {
			let newLabel = '';
			if (value.toLowerCase() == 'si') {
				newLabel = 'Cumplimiento';
			} else if (value.toLowerCase() == 'no') {
				newLabel = 'Incumplimiento';
			} else if (value.toLowerCase() == 'na') {
				newLabel = 'No Aplica';
			}
			return newLabel;
		},
		getPercentCompliance() {
			let percent = 0;
			let itemsYes = 0;
			this.optionsAnswer.forEach((option) => {
				if (option.value.toLowerCase() == 'si') {
					itemsYes = option.total;
				}
			});
			if (this.totalItemsApply > 0) {
				percent = parseFloat(
					parseFloat(
						((100 * itemsYes) / this.totalItemsApply).toString()
					).toFixed(0)
				);
			}
			return percent;
		},
		filterService(item, queryText) {
			return (
				item.pep_code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
					-1 ||
				item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
			);
		},
		addToolsInspections(tools) {
			tools.forEach((tool) => {
				const isRepeat = this.toolsInspection.some(
					(tool2) => tool2.id === tool.id
				);
				if (!isRepeat) {
					this.toolsInspection.push(tool);
				}
			});
		},
		removeToolsInspections(id) {
			const index = this.toolsInspection.findIndex((tool) => tool.id === id);
			if (index >= 0) this.toolsInspection.splice(index, 1);
		},
		isDisabled() {
			return true;
		},
		approveInspection() {
			Swal.fire({
				title: '¿Está seguro que desea aprobar esta inspección?',
				text: 'Confirme acción',
				icon: 'question',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'No, Cancelar',
				confirmButtonText: '¡Si, Aprobar!',
			}).then((result) => {
				if (result.value) {
					this.isLoadingSave = true;
					this.updateStatus({
						success: () => {
							this.isLoadingSave = false;
							if (this.$route.name == 'InspectionsRegisterRevision') {
								this.$router.push({
									name: 'InspectionsRegisterListRevision',
								});
							} else {
								this.$router.push({
									name: 'InspectionsRegisterList',
									params: { id: this.$route.params.inspectionId },
								});
							}
							$EventBus.$emit(
								'showSnack',
								'success',
								'Se ha registrado satisfactoriamente.'
							);
						},
						fail: (error) => {
							this.isLoadingSave = false;
							const { response } = error;
							if (response && response?.data?.message) {
								this.validationErrors = Array.isArray(
									response?.data?.message
								)
									? response?.data?.message
									: [];
							}
							$EventBus.$emit(
								'showSnack',
								'error',
								'Algo salio mal, no se guardo la información.'
							);
						},
						data: { format: this.inspection.format, id: this.register.id },
					});
				}
			});
		},
		backPage() {
			if (this.$route.name == 'InspectionsRegisterRevision') {
				this.$router.push({
					name: 'InspectionsRegisterListRevision',
				});
			} else {
				this.$router.push({
					name: 'InspectionsRegisterList',
					params: {
						id: this.$route.params.inspectionId,
					},
				});
			}
		},
		printPdf() {
			window.open(
				`/dashboard/inspections/${this.$route.params.inspectionId}/registers/${this.register.id}/pdf`,
				'Pdf',
				'resizable,scrollbars,status'
			);
		},
	},
	components: {
		FormHeader,
		FormBody,
		ToolDialog,
		ResponsibleInpection,
		ValidationAlert,
		RegistryCreator,
	},
};
