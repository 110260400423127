var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),(_vm.isLoadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h6 primary--text",domProps:{"textContent":_vm._s(_vm.inspection && _vm.inspection.code)}}),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s(_vm.inspection && _vm.textTransform(_vm.inspection.name))}})]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[(_vm.register && _vm.register.status == 'APPROVED')?_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){return _vm.printPdf()}}},[_c('v-icon',{staticClass:"ms-0 ps-0",attrs:{"size":"20"}},[_vm._v(" mdi-download ")]),_vm._v("Descargar ")],1):_vm._e()],1)],1),(_vm.register)?_c('responsible-inpection',{attrs:{"register":_vm.register}}):_vm._e(),(_vm.inspection)?_c('v-card',{staticClass:"my-10",attrs:{"elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"pb-0",attrs:{"elevation":"0","vertical":"","value":_vm.steps.current},model:{value:(_vm.steps.current),callback:function ($$v) {_vm.$set(_vm.steps, "current", $$v)},expression:"steps.current"}},[_c('v-stepper-step',{attrs:{"complete":"","step":"1","edit-icon":_vm.validateHeader()
									? 'mdi-check'
									: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == 1
									? 'primary'
									: _vm.validateHeader()
									? 'success'
									: 'error'}},[_vm._v(" Inicio ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('form-header',{attrs:{"headers":_vm.inspection.headers,"disabled":_vm.isDisabled()}}),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = 2}}},[_vm._v(" Siguiente ")])],1),_vm._l((_vm.inspection.bodies),function(group,i){return [_c('v-stepper-step',{key:i + 2 + 'step',attrs:{"complete":_vm.steps.views.includes(i + 2),"step":i + 2,"edit-icon":_vm.validateGroup(i)
										? 'mdi-check'
										: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == i + 2
										? 'primary'
										: _vm.validateGroup(i)
										? 'success'
										: 'error'}},[_vm._v(" "+_vm._s(_vm.textTransform(group.group))+" ")]),_c('v-stepper-content',{key:i + 2 + 'vsc',attrs:{"step":i + 2}},[_c('form-body',{attrs:{"group":group,"disabled":_vm.isDisabled()},model:{value:(group.items),callback:function ($$v) {_vm.$set(group, "items", $$v)},expression:"group.items"}}),_c('v-btn',{staticClass:"mb-3 mt-10",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = i + 3}}},[_vm._v(" Siguiente ")])],1)]}),_c('v-stepper-step',{key:3,attrs:{"complete":_vm.steps.views.includes(3),"step":3,"edit-icon":_vm.validateTools()
									? 'mdi-check'
									: 'mdi-alert-circle-outline',"color":_vm.steps.current == 3
									? 'primary'
									: _vm.validateTools()
									? 'success'
									: 'error',"editable":""}},[_vm._v(" Inspección de herramientas ")]),_c('v-stepper-content',{key:3 + 'vsc',attrs:{"step":3}},[_c('v-card',{staticClass:"ma-1 pa-2 pt-4 pb-8",attrs:{"elevation":1}},[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","depressed":"","disabled":_vm.isDisabled()},on:{"click":function($event){_vm.toolDialog = true}}},[_vm._v(" + Agregar herramienta ")]),_c('v-row',{attrs:{"align":"center","justify":"space-between","dense":""}},[_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('span',{staticClass:"body-2"},[_vm._v("Nombre")])]),_c('v-col',{attrs:{"cols":"5","md":"5"}},[_c('span',{staticClass:"body-2"},[_vm._v("Inspección")])]),(_vm.register && _vm.register.status == 'DRAFT')?_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('span',{staticClass:"body-2 text-no-wrap"},[_vm._v("Acciones")])]):_vm._e()],1),_vm._l((_vm.toolsInspection),function(item,index){return [_c('v-row',{key:index,attrs:{"align":"center","justify":"space-between","dense":""}},[_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.name))])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","dense":"","hide-details":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},_vm._l((_vm.inspection.tools_options_answer),function(option,iO){return _c('v-radio',{key:'radio-' + iO,staticClass:"mb-0 mr-3",attrs:{"label":option.label,"value":option.value,"color":option.color,"disabled":_vm.isDisabled()}})}),1)],1),(
												_vm.register && _vm.register.status == 'DRAFT'
											)?_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-icon',{on:{"click":function($event){return _vm.removeToolsInspections(item.id)}}},[_vm._v("mdi-delete")])],1):_vm._e()],1),(item.value == 'M' || item.value == 'R')?_c('v-row',{key:index + '-tool',staticClass:"my-2",attrs:{"align":"center","justify":"space-between","dense":"","cols":"12"}},[_c('v-col',[_c('v-textarea',{attrs:{"disabled":_vm.isDisabled(),"label":"Ingrese observaciones","hide-details":"","outlined":"","rows":"3"},model:{value:(item.observations),callback:function ($$v) {_vm.$set(item, "observations", $$v)},expression:"item.observations"}})],1)],1):_vm._e(),(index < _vm.toolsInspection.length - 1)?_c('v-divider',{key:index + 'hr',staticClass:"my-2"}):_vm._e()]})],2),_c('v-container',[_c('v-btn',{staticClass:"mb-3 mt-10",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = 4}}},[_vm._v(" Siguiente ")])],1)],1),_vm._l((_vm.inspection.bodyQuestions),function(group,i){return [_c('v-stepper-step',{key:i + 4,attrs:{"complete":_vm.steps.views.includes(i + 4),"step":i + 4,"edit-icon":_vm.validateQuestions()
										? 'mdi-check'
										: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == i + 4
										? 'primary'
										: _vm.validateQuestions()
										? 'success'
										: 'error'}},[_vm._v(" "+_vm._s(_vm.textTransform(group.group))+" ")]),_c('v-stepper-content',{key:i + 4 + 'vsc',attrs:{"step":i + 4}},_vm._l((group.items),function(question,j){return _c('div',{key:'group-q-' + j},[_c('v-textarea',{staticClass:"mt-2",attrs:{"label":_vm.textTransform(question.description),"disabled":_vm.isDisabled(),"hide-details":"","outlined":"","rows":"3"},model:{value:(question.value),callback:function ($$v) {_vm.$set(question, "value", $$v)},expression:"question.value"}}),(question.note)?_c('p',{staticClass:"mt-2 text--disabled",domProps:{"textContent":_vm._s(question.note)}}):_vm._e()],1)}),0)]})],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-12 px-6",attrs:{"elevation":"0"}},[_c('v-row',[_c('hr',{staticClass:"w-100"}),_c('v-col',{attrs:{"cols":"12"}},[_c('registry-creator',{attrs:{"register":_vm.register}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{},[_c('validation-alert',{attrs:{"validationErrors":_vm.validationErrors || []}})],1)]),_c('v-col',{attrs:{"cols":"12 d-flex justify-end"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"grey lighten-3"},on:{"click":function($event){return _vm.backPage()}}},[_vm._v(" Regresar ")]),_c('validate-activity',{attrs:{"module":"aprobacion","page":"aprobarRegistro","activity":"aprobarRegistro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var isDisabled = ref.isDisabled;
return [(
											_vm.register &&
											_vm.register.status == 'PENDING' &&
											_vm.$route.name ==
												'InspectionsRegisterRevision'
										)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.isLoadingSave,"disabled":isDisabled},on:{"click":function($event){return _vm.approveInspection()}}},[_vm._v(" Aprobar ")]):_vm._e()]}}],null,false,3696271704)})],1)],1)],1)],1)],1)],1):_vm._e()],1),_c('tool-dialog',{attrs:{"toolsSelected":_vm.toolsInspection},on:{"addToolsInspections":_vm.addToolsInspections},model:{value:(_vm.toolDialog),callback:function ($$v) {_vm.toolDialog=$$v},expression:"toolDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }